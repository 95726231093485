import {SitemapRangeIdentifier} from "../modules/pages/archive-sitemap-page/types/SitemapRangeIdentifier";


export class Routing {
  public static HOME_URL_MATCHER = '^(\\/*ru\\/*)?$';
  public static TAG_URL_MATCHER = '([A-Za-z0-9_=-]+)_t([0-9]+)';
  public static NEWS_URL_MATCHER = '([A-Za-z0-9_=-]+)_n([0-9]+)';
  public static STATIC_URL_MATCHER = '([A-Za-z0-9_=-]+)';
  public static AUTHOR_URL_MATCHER = '(^(\\/*(ru|uk)\\/*)authors\\/(([0-9]+)-[A-Za-z0-9_=-]+))'; //???
  public static EDITORS_URL_MATCHER = '(^(\\/*(ru|uk)\\/*)authors)';
  public static CONTACTS_URL_MATCHER = '(^(\\/*(ru|uk)\\/*)contacts)';
  public static ID_FROM_TAG_URL_MATCHER = '^[0-9]+';
  public static ID_TRANSLITERATION_MATCHER = '(([0-9]+)-[A-Za-z0-9_=-]+)';
  public static ID_FROM_URL_MATCHER = '^[0-9]+';
  public static ID_NEWS_TRANSLITERATION_MATCHER = '(news/([0-9]+)-[A-Za-z0-9_=-]+)';
  public static SITEMAP_URL_MATCHER = '(^(\\/*(ru|uk)\\/*)?html-sitemap)';
  public static SITEMAP_DATE_MATCHER = '^sitemap-(_?.*)';
  public static SITEMAP_WEEK_MATCHER = `^(${SitemapRangeIdentifier.THIS_WEEK}|${SitemapRangeIdentifier.LAST_WEEK})`;
}
